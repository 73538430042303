module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-8f147faebc/0/cache/gatsby-plugin-catch-links-npm-5.14.0-3e7b33ed41-75112511c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-5f435b50d7/0/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-9663fdaca2.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFFFFF","display":"standalone","icon":"src/images/favicon.ico","name":"Alysia Lynn","start_url":"/","theme_color":"#6E00FF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6f09b4bd5778216b8b05f98f17fd1f17"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-6ee6e24872/0/cache/gatsby-plugin-styled-components-npm-6.14.0-79a8dd43a8-b3217211eb.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-anchor-links-npm-1.2.1-7db63a8548-fa57ad4530.zip/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-75},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-a946711440/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
